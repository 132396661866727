/**
 * Simpler shortcut to create easily break points
 */
/**
 * For hacks. Use wisely.
 */
/**
 * Object mixin to make polyfill working.
 */
/**
 * Easy functions to create easily pure css arrows anywhere
 */
@import 'flickity.min.css';
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.no-intro-bottom-margin {
  margin-bottom: 0 !important;
}

.no-top-padding {
  padding-top: 0 !important;
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.25rem;
}
.slick-dots button {
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #133C7D;
  text-indent: -9999px;
}
.slick-dots li.slick-active button {
  background-color: #4CC28F;
}

.display-none {
  display: none !important;
}

.active-language {
  color: #4CC28F;
}

.anchor-dest-with-headline {
  scroll-margin-top: 150px;
}
@media only screen and (min-width: 1200px) {
  .anchor-dest-with-headline {
    scroll-margin-top: 350px;
  }
}
@media only screen and (min-width: 1680px) {
  .anchor-dest-with-headline {
    scroll-margin-top: 270px;
  }
}

@font-face {
  font-family: "sourcesanspro-black";
  src: url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-900.woff2") format("woff2"), url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-900.woff") format("woff"), url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-900.eot") format("eot");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "sourcesanspro-bold";
  src: url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-600.woff2") format("woff2"), url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-600.woff") format("woff"), url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-600.eot") format("eot");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "sourcesanspro-regular";
  src: url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-regular.woff2") format("woff2"), url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-regular.woff") format("woff"), url("assets/fonts/sourcesanspro/source-sans-pro-v14-latin-regular.eot") format("eot");
  font-style: normal;
  font-weight: 400;
}
body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-hyphens: auto;
          hyphens: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.text__f1, .text__f1--black, .banner-fullwidth__lead, .news-intro__headline, .news-intro__headline--related-entries, .news-intro__headline--overview, .blank-intro__headline, .fullwidth-intro__headline {
  font-size: 40px;
  font-size: 4vw;
  line-height: 1.1;
}
@media (max-width: 625px) {
  .text__f1, .text__f1--black, .banner-fullwidth__lead, .news-intro__headline, .news-intro__headline--related-entries, .news-intro__headline--overview, .blank-intro__headline, .fullwidth-intro__headline {
    font-size: 25px;
  }
}
@media (min-width: 1375px) {
  .text__f1, .text__f1--black, .banner-fullwidth__lead, .news-intro__headline, .news-intro__headline--related-entries, .news-intro__headline--overview, .blank-intro__headline, .fullwidth-intro__headline {
    font-size: 55px;
  }
}
.text__f1--black, .banner-fullwidth__lead, .news-intro__headline, .news-intro__headline--related-entries, .news-intro__headline--overview, .blank-intro__headline, .fullwidth-intro__headline {
  font-family: "sourcesanspro-black";
  font-weight: 900;
}
.text__f2, .text__f2--black, .wysiwyg__text h2, .optional-headline__wysiwyg, .optional-headline__wysiwyg--light, .optional-headline__wysiwyg--dark {
  font-size: 33px;
  font-size: 3.3vw;
  line-height: 1.1;
}
@media (max-width: 606.0606060606px) {
  .text__f2, .text__f2--black, .wysiwyg__text h2, .optional-headline__wysiwyg, .optional-headline__wysiwyg--light, .optional-headline__wysiwyg--dark {
    font-size: 20px;
  }
}
@media (min-width: 1424.2424242424px) {
  .text__f2, .text__f2--black, .wysiwyg__text h2, .optional-headline__wysiwyg, .optional-headline__wysiwyg--light, .optional-headline__wysiwyg--dark {
    font-size: 47px;
  }
}
.text__f2--black, .wysiwyg__text h2, .optional-headline__wysiwyg, .optional-headline__wysiwyg--light, .optional-headline__wysiwyg--dark {
  font-family: "sourcesanspro-black";
  font-weight: 900;
}
.text__f3, .text__f3--regular, .search__input::placeholder, .text__f3--bold, .search__lead, .search__result-title, .search__input, .search__maintitle, .text__f3--black, .news-teaser__headline, .wysiwyg__text h3, .home-slider .slide__content-item__time, .home-slider .slide__content-item__time--purple, .home-slider .slide__content-item__time--green, .home-slider .slide__content-item__time--blue, .testimonial__headline, .teaser__headline, .dynamic-blocks__wysiwyg-block .wysiwyg h3 {
  font-size: 24px;
  font-size: 2.4vw;
  line-height: 1.1;
}
@media (max-width: 750px) {
  .text__f3, .text__f3--regular, .search__input::placeholder, .text__f3--bold, .search__lead, .search__result-title, .search__input, .search__maintitle, .text__f3--black, .news-teaser__headline, .wysiwyg__text h3, .home-slider .slide__content-item__time, .home-slider .slide__content-item__time--purple, .home-slider .slide__content-item__time--green, .home-slider .slide__content-item__time--blue, .testimonial__headline, .teaser__headline, .dynamic-blocks__wysiwyg-block .wysiwyg h3 {
    font-size: 18px;
  }
}
@media (min-width: 1250px) {
  .text__f3, .text__f3--regular, .search__input::placeholder, .text__f3--bold, .search__lead, .search__result-title, .search__input, .search__maintitle, .text__f3--black, .news-teaser__headline, .wysiwyg__text h3, .home-slider .slide__content-item__time, .home-slider .slide__content-item__time--purple, .home-slider .slide__content-item__time--green, .home-slider .slide__content-item__time--blue, .testimonial__headline, .teaser__headline, .dynamic-blocks__wysiwyg-block .wysiwyg h3 {
    font-size: 30px;
  }
}
.text__f3--black, .news-teaser__headline, .wysiwyg__text h3, .home-slider .slide__content-item__time, .home-slider .slide__content-item__time--purple, .home-slider .slide__content-item__time--green, .home-slider .slide__content-item__time--blue, .testimonial__headline, .teaser__headline, .dynamic-blocks__wysiwyg-block .wysiwyg h3 {
  font-family: "sourcesanspro-black";
  font-weight: 900;
}
.text__f3--bold, .search__lead, .search__result-title, .search__input, .search__maintitle {
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.text__f3--regular, .search__input::placeholder {
  font-family: "sourcesanspro-regular";
  font-weight: 400;
}
.text__f4, .text__f4--regular, textarea, .form input:not([type=submit]),
.form select, .wysiwyg__text, .home-slider .slide__content-item__headline, .testimonial-slider .slide__quote, .testimonial__text, .teaser__wysiwyg, .search__url-link, .search__info, .accordion__text h3, .accordion__text, .text__f5--regular, .dynamic-blocks__wysiwyg-block .wysiwyg, .text__f4--bold, .home-slider .slide__content-item__headline b,
.home-slider .slide__content-item__headline strong, .accordion__link, .accordion h2, .text__f5--bold, .text__f4--black, .form__label, .testimonial-slider .slide__author, .testimonial__subheadline, .dynamic-blocks__teaser-block .headline, .subfooter__claim, .footer__headline, .footer__claim, .text__f5--black, .circle-teaser--dark .circle-teaser__headline, .circle-teaser--light .circle-teaser__headline, .accordion__title-text, .btn, .btn--small, .btn--green, .btn--dark, .form button,
.form input[type=submit], .btn--light, .text__f5 {
  font-size: 17px;
  font-size: 1.7vw;
  line-height: 1.5;
}
@media (max-width: 882.3529411765px) {
  .text__f4, .text__f4--regular, textarea, .form input:not([type=submit]),
.form select, .wysiwyg__text, .home-slider .slide__content-item__headline, .testimonial-slider .slide__quote, .testimonial__text, .teaser__wysiwyg, .search__url-link, .search__info, .accordion__text h3, .accordion__text, .text__f5--regular, .dynamic-blocks__wysiwyg-block .wysiwyg, .text__f4--bold, .home-slider .slide__content-item__headline b,
.home-slider .slide__content-item__headline strong, .accordion__link, .accordion h2, .text__f5--bold, .text__f4--black, .form__label, .testimonial-slider .slide__author, .testimonial__subheadline, .dynamic-blocks__teaser-block .headline, .subfooter__claim, .footer__headline, .footer__claim, .text__f5--black, .circle-teaser--dark .circle-teaser__headline, .circle-teaser--light .circle-teaser__headline, .accordion__title-text, .btn, .btn--small, .btn--green, .btn--dark, .form button,
.form input[type=submit], .btn--light, .text__f5 {
    font-size: 15px;
  }
}
@media (min-width: 1176.4705882353px) {
  .text__f4, .text__f4--regular, textarea, .form input:not([type=submit]),
.form select, .wysiwyg__text, .home-slider .slide__content-item__headline, .testimonial-slider .slide__quote, .testimonial__text, .teaser__wysiwyg, .search__url-link, .search__info, .accordion__text h3, .accordion__text, .text__f5--regular, .dynamic-blocks__wysiwyg-block .wysiwyg, .text__f4--bold, .home-slider .slide__content-item__headline b,
.home-slider .slide__content-item__headline strong, .accordion__link, .accordion h2, .text__f5--bold, .text__f4--black, .form__label, .testimonial-slider .slide__author, .testimonial__subheadline, .dynamic-blocks__teaser-block .headline, .subfooter__claim, .footer__headline, .footer__claim, .text__f5--black, .circle-teaser--dark .circle-teaser__headline, .circle-teaser--light .circle-teaser__headline, .accordion__title-text, .btn, .btn--small, .btn--green, .btn--dark, .form button,
.form input[type=submit], .btn--light, .text__f5 {
    font-size: 20px;
  }
}
.text__f4--black, .form__label, .testimonial-slider .slide__author, .testimonial__subheadline, .dynamic-blocks__teaser-block .headline, .subfooter__claim, .footer__headline, .footer__claim, .text__f5--black, .circle-teaser--dark .circle-teaser__headline, .circle-teaser--light .circle-teaser__headline, .accordion__title-text, .btn, .btn--small, .btn--green, .btn--dark, .form button,
.form input[type=submit], .btn--light {
  font-family: "sourcesanspro-black";
  font-weight: 900;
}
.text__f4--bold, .home-slider .slide__content-item__headline b,
.home-slider .slide__content-item__headline strong, .accordion__link, .accordion h2, .text__f5--bold {
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.text__f4--regular, textarea, .form input:not([type=submit]),
.form select, .wysiwyg__text, .home-slider .slide__content-item__headline, .testimonial-slider .slide__quote, .testimonial__text, .teaser__wysiwyg, .search__url-link, .search__info, .accordion__text h3, .accordion__text, .text__f5--regular, .dynamic-blocks__wysiwyg-block .wysiwyg {
  font-family: "sourcesanspro-regular";
  font-weight: 400;
}
.text__f4-nav, .text__f4-nav--regular, .nav__language-divider, .text__f4-nav--bold, .text__f4-nav--black, .nav__language-item, .nav__subnav-inner, .nav__contact-link, .nav__main-link {
  font-size: 16px;
  font-size: 1.6vw;
  line-height: 1.5;
}
@media (max-width: 875px) {
  .text__f4-nav, .text__f4-nav--regular, .nav__language-divider, .text__f4-nav--bold, .text__f4-nav--black, .nav__language-item, .nav__subnav-inner, .nav__contact-link, .nav__main-link {
    font-size: 14px;
  }
}
@media (min-width: 1125px) {
  .text__f4-nav, .text__f4-nav--regular, .nav__language-divider, .text__f4-nav--bold, .text__f4-nav--black, .nav__language-item, .nav__subnav-inner, .nav__contact-link, .nav__main-link {
    font-size: 18px;
  }
}
.text__f4-nav--black, .nav__language-item, .nav__subnav-inner, .nav__contact-link, .nav__main-link {
  font-family: "sourcesanspro-black";
  font-weight: 900;
}
.text__f4-nav--bold {
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.text__f4-nav--regular, .nav__language-divider {
  font-family: "sourcesanspro-regular";
  font-weight: 400;
}
.text__f6, .text__f7, .text__f6--regular, .form .errors li, .text__f7--regular, .text__f8--regular, .text__f6--bold, .news-teaser__link, .testimonial-slider .slide__text, .testimonial__link, .teaser__cta-text, .dynamic-blocks__teaser-block .link, .circle-teaser__link, .accordion__icon--minus, .accordion__icon--plus, .footer__link, .footer__legal-item, .footer__email, .footer__phone, .footer__wysiwyg, .text__f7--bold, .text__f8--bold, .text__f6--black, .btn--small, .text__f7--black, .text__f8--black, .text__f8 {
  font-size: 15px;
  font-size: 1.5vw;
  line-height: 1.3;
}
@media (max-width: 800px) {
  .text__f6, .text__f7, .text__f6--regular, .form .errors li, .text__f7--regular, .text__f8--regular, .text__f6--bold, .news-teaser__link, .testimonial-slider .slide__text, .testimonial__link, .teaser__cta-text, .dynamic-blocks__teaser-block .link, .circle-teaser__link, .accordion__icon--minus, .accordion__icon--plus, .footer__link, .footer__legal-item, .footer__email, .footer__phone, .footer__wysiwyg, .text__f7--bold, .text__f8--bold, .text__f6--black, .btn--small, .text__f7--black, .text__f8--black, .text__f8 {
    font-size: 12px;
  }
}
@media (min-width: 1066.6666666667px) {
  .text__f6, .text__f7, .text__f6--regular, .form .errors li, .text__f7--regular, .text__f8--regular, .text__f6--bold, .news-teaser__link, .testimonial-slider .slide__text, .testimonial__link, .teaser__cta-text, .dynamic-blocks__teaser-block .link, .circle-teaser__link, .accordion__icon--minus, .accordion__icon--plus, .footer__link, .footer__legal-item, .footer__email, .footer__phone, .footer__wysiwyg, .text__f7--bold, .text__f8--bold, .text__f6--black, .btn--small, .text__f7--black, .text__f8--black, .text__f8 {
    font-size: 16px;
  }
}
.text__f6--black, .btn--small, .text__f7--black, .text__f8--black {
  font-family: "sourcesanspro-black";
  font-weight: 900;
}
.text__f6--bold, .news-teaser__link, .testimonial-slider .slide__text, .testimonial__link, .teaser__cta-text, .dynamic-blocks__teaser-block .link, .circle-teaser__link, .accordion__icon--minus, .accordion__icon--plus, .footer__link, .footer__legal-item, .footer__email, .footer__phone, .footer__wysiwyg, .text__f7--bold, .text__f8--bold {
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.text__f6--regular, .form .errors li, .text__f7--regular, .text__f8--regular {
  font-family: "sourcesanspro-regular";
  font-weight: 400;
}

.btn, .btn--small, .btn--green, .btn--dark, .form button,
.form input[type=submit], .btn--light {
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.8rem 2.3rem;
  display: inline-block;
}
@media only screen and (min-width: 1200px) {
  .btn, .btn--small, .btn--green, .btn--dark, .form button,
.form input[type=submit], .btn--light {
    padding: 1rem 4rem;
  }
}
.btn:hover, .btn--small:hover, .btn--green:hover, .btn--dark:hover, .form button:hover,
.form input[type=submit]:hover, .btn--light:hover {
  background-color: #4CC28F;
  color: #ffffff;
}
.btn--light {
  background: #ffffff;
  color: #133C7D;
}
.btn--dark, .form button,
.form input[type=submit] {
  background: #133C7D;
  color: #ffffff;
}
.btn--green {
  background: #4CC28F;
  color: #ffffff;
}
.btn--green:hover {
  background: #133C7D;
}
.btn--small {
  padding: 1rem 5rem;
}

.icon, .icon__search, .icon__linkedin, .icon__linkedin--mobile-nav, .icon__linkedin--nav, .icon__youtube, .icon__youtube--mobile-nav, .icon__youtube--nav, .icon__mail, .icon__phone, .icon__minus-nav, .icon__minus, .icon__plus, .icon__minus--white, .icon__plus--white, .icon__paper-plane, .icon__paper-plane--green {
  transform: scale(1);
  transition: ease-in all 0.15s;
}
.icon:hover, .icon__search:hover, .icon__linkedin:hover, .icon__linkedin--mobile-nav:hover, .icon__linkedin--nav:hover, .icon__youtube:hover, .icon__youtube--mobile-nav:hover, .icon__youtube--nav:hover, .icon__mail:hover, .icon__phone:hover, .icon__minus-nav:hover, .icon__minus:hover, .icon__plus:hover, .icon__minus--white:hover, .icon__plus--white:hover, .icon__paper-plane:hover, .icon__paper-plane--green:hover {
  transform: scale(0.93);
  transition: ease-in all 0.15s;
}
.icon__paper-plane, .icon__paper-plane--green {
  margin-right: 0.5rem;
  width: 20px;
}
.icon__minus, .icon__plus, .icon__minus--white, .icon__plus--white {
  width: 11px;
  margin-left: 0.3rem;
}
.icon__minus--white, .icon__plus--white {
  width: 9px;
  margin-top: -2px;
  margin-right: 0;
  margin-left: 0.4rem;
}
.icon__minus-nav {
  top: 20px;
  right: 0;
  position: absolute;
}
.icon__phone {
  width: inherit5%;
  margin-right: 5px;
}
.icon__mail {
  width: inherit5%;
  margin-right: 5px;
}
.icon__youtube, .icon__youtube--mobile-nav, .icon__youtube--nav {
  width: 26px;
  margin-right: 15px;
}
.icon__youtube--nav {
  margin-right: 8px;
}
@media only screen and (min-width: 1200px) {
  .icon__youtube--nav {
    margin-right: 13px;
  }
}
.icon__youtube--mobile-nav {
  width: 20px;
}
.icon__linkedin, .icon__linkedin--mobile-nav, .icon__linkedin--nav {
  width: 23px;
  margin-top: -5px;
}
.icon__linkedin--nav {
  margin-top: -3px;
  width: 18px;
}
.icon__linkedin--mobile-nav {
  width: 17px;
}
.icon__search {
  width: 18px;
}
.icon__close {
  margin-left: -5px;
  margin-right: -3px;
}

.nav__mobile-bar {
  position: fixed;
  background: #F5F5F5;
  box-shadow: 0 3px 6px #00000029;
  z-index: 99999;
  width: 93%;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 1rem;
  padding: 0;
}
.nav__mobile-bar-item, .nav__mobile-bar-item--menu {
  display: inline-block;
  /* margin: 0 .5rem; */
  height: 54px;
  padding-top: 13px;
  padding-right: 18px;
  padding-left: 15px;
  border-right: 1px solid lightgray;
}
.nav__mobile-bar-item--menu {
  padding-left: 21px;
  padding-right: 25px;
}
.nav__mobile-subnav {
  display: none;
  position: fixed;
  background: #F5F5F5;
  box-shadow: 0 3px 6px #00000029;
  z-index: 99999;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 5rem;
  width: 93%;
  max-height: 100%;
  overflow-y: scroll;
}
.nav__mobile-subnav-item {
  box-shadow: 0 3px 6px #00000029;
  padding: 1rem 1rem 0;
}
.nav__mobile-subnav-item a {
  display: inline-block;
  text-decoration: none;
}
.nav__mobile-social-media {
  padding: 1rem 0;
}
.nav__bar {
  position: fixed;
  background: #F5F5F5;
  box-shadow: 0 3px 6px #00000029;
  z-index: 99999;
  width: 95%;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 2rem;
  padding: 1rem 0;
}
.nav__logo {
  width: 70px;
  padding: 0.5rem 0;
}
@media only screen and (min-width: 768px) {
  .nav__logo {
    margin-left: 15px;
    padding: 0;
    width: 80px;
  }
}
@media only screen and (min-width: 1024px) {
  .nav__logo {
    margin-left: 15px;
    width: 100px;
  }
}
@media only screen and (min-width: 1200px) {
  .nav__logo {
    margin-left: 25px;
    width: 120px;
  }
}
@media only screen and (min-width: 1440px) {
  .nav__logo {
    margin-left: 0;
    width: 130px;
  }
}
.nav__main-links li,
.nav__main-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.nav__main-link {
  color: #133C7D;
  display: inline-block;
  text-transform: uppercase;
}
.nav__main-link a {
  color: #133C7D;
  margin: 0 1rem;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .nav__main-link a {
    margin: 0 0.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nav__main-link a {
    margin: 0 0.6rem;
  }
}
@media only screen and (min-width: 1200px) {
  .nav__main-link a {
    margin: 0 0.5rem;
  }
}
@media only screen and (min-width: 1440px) {
  .nav__main-link a {
    margin: 0 1rem;
  }
}
.nav__main-link a:hover {
  color: #4CC28F;
}
.nav__main-link .active {
  color: #4CC28F;
}
.nav__icons {
  margin-top: -5px;
}
.nav__search {
  margin-right: 10px;
}
@media only screen and (min-width: 1200px) {
  .nav__search {
    margin-right: 30px;
  }
}
.nav__contact-link {
  display: inline-block;
  text-transform: uppercase;
  color: #133C7D;
  text-decoration: none;
  margin-left: 2rem;
  border-bottom: 3px transparent solid;
}
.nav__contact-link:hover {
  color: #4CC28F;
}
.nav__contact-link:hover .nav__subnav {
  display: inline-block !important;
}
.nav__contact-link .active {
  color: #4CC28F;
}
.nav__subnav {
  display: none;
  position: fixed;
  background: #F5F5F5;
  box-shadow: 0 3px 6px #00000029;
  z-index: 99999;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 8rem;
  width: 700px;
}
@media only screen and (min-width: 1200px) {
  .nav__subnav {
    margin-top: 10rem;
  }
}
.nav__subnav:before {
  border-bottom: 30px solid transparent;
  border-top: 30px solid transparent;
  content: "";
  display: block;
  height: 18px;
  position: absolute;
  top: -46px;
  width: 100%;
}
.nav__subnav-outer {
  height: 280px;
  position: relative;
  display: flex;
  align-items: center;
}
.nav__subnav-link {
  height: 150px;
  background: gray;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  position: absolute;
  width: 100%;
}
.nav__subnav-items {
  display: inline-block;
  margin-right: -5px;
}
.nav__subnav-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 233px;
  box-shadow: none;
  transition: all ease-in 0.2s;
}
.nav__subnav-outer:hover {
  box-shadow: 3px 3px 6px #00000029;
  transition: all ease-in 0.2s;
}
.nav__subnav-outer:hover .nav__subnav-inner {
  color: #4CC28F;
}
.nav__subnav-inner {
  color: #133C7D;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  line-height: 1.1;
}
@media only screen and (min-width: 1200px) {
  .nav__subnav-inner {
    padding: 0 0.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .nav__language-items {
    margin-left: 3rem;
  }
}
@media only screen and (min-width: 1440px) {
  .nav__language-items {
    margin-left: 2rem;
  }
}
@media only screen and (min-width: 1680px) {
  .nav__language-items {
    margin-left: 3rem;
  }
}
.nav__language-item {
  color: #133C7D;
  display: inline-block;
  text-transform: uppercase;
  color: #133C7D;
  margin: 0 0.2rem;
  text-decoration: none;
}
.nav__language-item:hover {
  color: #4CC28F;
}
.nav__language-divider {
  color: #133C7D;
}

.footer {
  background: #F5F5F5;
  padding: 2rem 0 1.5rem;
  box-shadow: 0 3px 6px #00000029;
  margin-bottom: 5px;
}
@media only screen and (min-width: 1200px) {
  .footer {
    padding: 4rem 0;
  }
}
.footer__cta {
  padding: 0.4rem 0.7rem;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 1680px) {
  .footer__cta {
    padding: 1rem 5rem;
    margin-top: 1rem;
  }
}
.footer__logo {
  width: 100px;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1200px) {
  .footer__logo {
    width: 150px;
    margin-bottom: 3rem;
  }
}
.footer__claim {
  color: #133C7D;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1200px) {
  .footer__claim {
    margin-bottom: 0;
  }
}
.footer__headline {
  color: #133C7D;
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.3rem;
}
@media only screen and (min-width: 480px) {
  .footer__headline {
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .footer__headline {
    margin-bottom: 1rem;
  }
}
.footer__wysiwyg {
  color: #6d86ad;
}
.footer__links li,
.footer__links ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}
.footer__contact-cta {
  margin-top: 1rem;
}
@media only screen and (min-width: 1200px) {
  .footer__contact-cta {
    margin-top: 5rem;
  }
}
.footer__email, .footer__phone {
  color: #133C7D;
  display: block;
  text-decoration: none;
}
.footer__email:hover, .footer__phone:hover {
  text-decoration: underline;
}
.footer__email {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 1024px) {
  .footer__email {
    margin-bottom: 1rem;
  }
}
.footer__legal {
  margin-top: 2rem;
}
@media only screen and (min-width: 1200px) {
  .footer__legal {
    margin-top: 5rem;
  }
}
.footer__legal-item {
  display: inline-block;
  margin-right: 1rem;
  text-decoration: none;
  color: #6d86ad;
}
@media only screen and (min-width: 1200px) {
  .footer__legal-item {
    margin-right: 4rem;
  }
}
.footer__legal-item:hover {
  color: #4CC28F;
}
.footer__legal-item:last-of-type {
  margin-right: 0;
}
.footer__address {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1200px) {
  .footer__address {
    margin-bottom: 0;
  }
}
.footer__link {
  color: #6d86ad;
  margin-bottom: 0.7rem;
}
.footer__link:last-of-type {
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) {
  .footer__link {
    margin-bottom: 1rem;
  }
}
.footer__link a {
  color: #6d86ad;
}
.footer__link a:hover {
  color: #4CC28F;
}
.footer__link .active {
  color: #4CC28F;
}
.footer__link a {
  text-decoration: none;
}

.subfooter {
  background: #F5F5F5;
  padding: 2rem 0;
}
.subfooter__claim {
  color: #6d86ad;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 1200px) {
  .subfooter__claim {
    margin-bottom: 0;
  }
}
.subfooter__partnerlogos {
  display: inline-block;
  margin-right: 0;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 1200px) {
  .subfooter__partnerlogos {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
}
.subfooter__partnerlogos:last-of-type {
  margin-right: 0;
}

.module, .module--no-bottom-padding {
  padding-bottom: 3rem;
}
@media only screen and (min-width: 768px) {
  .module, .module--no-bottom-padding {
    padding-bottom: 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .module, .module--no-bottom-padding {
    padding-bottom: 5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .module, .module--no-bottom-padding {
    padding-bottom: 6rem;
  }
}
@media only screen and (min-width: 1440px) {
  .module, .module--no-bottom-padding {
    padding-bottom: 7rem;
  }
}
.module--no-bottom-padding {
  padding-bottom: 0;
}

.fullwidth-intro {
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
  min-height: 100vh;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .fullwidth-intro {
    min-height: 500px;
    margin-bottom: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  .fullwidth-intro {
    min-height: 550px;
  }
}
@media only screen and (min-width: 1200px) {
  .fullwidth-intro {
    min-height: 720px;
  }
}
.fullwidth-intro__content {
  color: #ffffff;
  padding: 27rem 0 3rem;
}
@media only screen and (min-width: 768px) {
  .fullwidth-intro__content {
    padding: 19rem 0 4rem;
  }
}
@media only screen and (min-width: 1024px) {
  .fullwidth-intro__content {
    padding: 17rem 0 4rem;
  }
}
@media only screen and (min-width: 1200px) {
  .fullwidth-intro__content {
    padding: 17rem 0 7rem;
  }
}
.fullwidth-intro__headline {
  -webkit-hyphens: none;
          hyphens: none;
}
.fullwidth-intro__cta {
  margin-top: 2rem;
}
@media only screen and (min-width: 1200px) {
  .fullwidth-intro__cta {
    margin-top: 3rem;
  }
}
@media only screen and (min-width: 1440px) {
  .fullwidth-intro__cta {
    margin-top: 4rem;
  }
}

.blank-intro {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1200px) {
  .blank-intro {
    min-height: 300px;
    margin-bottom: 3rem;
  }
}
.blank-intro__content {
  color: #133C7D;
  padding: 9rem 0 2rem;
}
@media only screen and (min-width: 1200px) {
  .blank-intro__content {
    padding: 19rem 0 2rem;
  }
}
.blank-intro__cta {
  margin-top: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .blank-intro__cta {
    margin-top: 4rem;
  }
}

.news-intro {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1200px) {
  .news-intro {
    min-height: 300px;
  }
}
@media only screen and (min-width: 1200px) {
  .news-intro.small-space {
    min-height: auto;
  }
}
.news-intro__content, .news-intro__content--overview {
  color: #133C7D;
  padding: 8rem 0 0.5rem;
}
@media only screen and (min-width: 768px) {
  .news-intro__content, .news-intro__content--overview {
    padding: 11rem 0 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .news-intro__content, .news-intro__content--overview {
    padding: 15rem 0 2rem;
  }
}
@media only screen and (min-width: 1440px) {
  .news-intro__content, .news-intro__content--overview {
    padding: 15rem 0 4rem;
  }
}
.news-intro__content.small-space, .small-space.news-intro__content--overview {
  padding: 2rem 0 0.5rem;
  min-height: 150px;
}
@media only screen and (min-width: 1440px) {
  .news-intro__content--overview {
    padding: 15rem 0 2rem;
  }
}
.news-intro__content--overview.small-space {
  padding: 2rem 0 0.5rem;
  min-height: 150px;
}
.news-intro__headline, .news-intro__headline--related-entries, .news-intro__headline--overview {
  margin-bottom: 1rem;
  -webkit-hyphens: none;
          hyphens: none;
}
@media only screen and (min-width: 1200px) {
  .news-intro__headline, .news-intro__headline--related-entries, .news-intro__headline--overview {
    margin-bottom: 2.5rem;
  }
}
@media only screen and (min-width: 1440px) {
  .news-intro__headline, .news-intro__headline--related-entries, .news-intro__headline--overview {
    margin-bottom: 2.5rem;
  }
}
.news-intro__headline--overview {
  margin-bottom: 0;
}
.news-intro__headline--related-entries {
  color: #133C7D;
}
.news-intro__cta {
  margin-top: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .news-intro__cta {
    margin-top: 4rem;
  }
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  -webkit-user-select: none;
          user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  -webkit-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  -webkit-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}
.glide--rtl {
  direction: rtl;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.accordion b,
.accordion strong {
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.accordion h2 {
  text-transform: uppercase;
}
.accordion a {
  color: #4CC28F !important;
}
.accordion a:hover {
  text-decoration: none !important;
}
.accordion ul li {
  list-style: none;
  background: url("assets/img/svg/plus-green.svg") no-repeat left 0 top 13px;
  display: block;
  list-style: none;
  padding-left: 15px;
}
@media only screen and (min-width: 1024px) {
  .accordion ul li {
    padding-left: 22px;
  }
}
.accordion ol {
  list-style-type: none;
  padding-left: 0;
}
.accordion ol li:before {
  content: counter(item, decimal-leading-zero) " ";
  counter-increment: item;
  color: #4CC28F;
  margin-right: 0.5rem;
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.accordion__wrapper, .accordion__wrapper--nav {
  box-shadow: 0 3px 6px #00000029;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1200px) {
  .accordion__wrapper, .accordion__wrapper--nav {
    margin-bottom: 1.5rem;
  }
}
.accordion__wrapper--nav {
  margin-bottom: 0;
}
.accordion__title, .accordion__title--nav {
  color: #133C7D;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1.3rem 0;
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) {
  .accordion__title, .accordion__title--nav {
    padding: 1.5rem 0;
  }
}
@media only screen and (min-width: 1440px) {
  .accordion__title, .accordion__title--nav {
    padding: 2rem 0;
  }
}
.accordion__title:hover .accordion__icon--minus, .accordion__title--nav:hover .accordion__icon--minus,
.accordion__title:hover .accordion__icon--plus,
.accordion__title--nav:hover .accordion__icon--plus {
  transition: ease-in all 0.15s;
}
.accordion__title:hover .accordion__icon--minus .st0, .accordion__title--nav:hover .accordion__icon--minus .st0,
.accordion__title:hover .accordion__icon--plus .st0,
.accordion__title--nav:hover .accordion__icon--plus .st0 {
  fill: blue;
  background-color: yellow;
  padding: 5px;
}
.accordion__title--nav {
  padding-left: 1rem;
  padding-right: 1rem;
}
.accordion__title-text {
  color: #133C7D;
  flex: 2 1 auto;
  padding-right: 1rem;
}
.accordion__title-text a {
  text-decoration: none;
  color: inherit;
}
.accordion__link {
  color: #000000;
  text-decoration: none;
}
.accordion__link-img {
  width: 37px;
  margin-left: 0.5rem !important;
}
@media only screen and (min-width: 1200px) {
  .accordion__link-img {
    width: 40px;
  }
}
.accordion__icon, .accordion__icon--nav {
  float: right;
  position: absolute;
  right: 10px;
}
.accordion__icon--minus, .accordion__icon--plus {
  color: #4CC28F;
  text-transform: none;
}
.accordion__icon--minus .inner, .accordion__icon--plus .inner {
  display: none;
}
@media only screen and (min-width: 768px) {
  .accordion__icon--minus .inner, .accordion__icon--plus .inner {
    display: inline-block;
  }
}
.accordion__icon--plus {
  display: inline-block;
}
.accordion__icon--minus {
  display: none;
  margin-right: -14px;
}
.accordion__icon--nav {
  right: 20px;
}
.accordion__text {
  color: #133C7D;
  padding: 0 0 1rem;
}
@media only screen and (min-width: 1200px) {
  .accordion__text {
    padding: 1rem 3rem 3rem 2rem;
  }
}
.accordion__text figure {
  margin-left: 0;
}
.accordion__text figure img {
  max-width: 100%;
}
.accordion__text ul {
  padding-left: 0;
}
.accordion__text h3 {
  padding-bottom: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .accordion__text h3 {
    padding-bottom: 1.5rem;
  }
}
.accordion__text a {
  color: #000000;
}
.accordion__wrapper.js-collapsed .accordion__content, .accordion__wrapper.js-collapsed .accordion__content--nav, .js-collapsed.accordion__wrapper--nav .accordion__content, .js-collapsed.accordion__wrapper--nav .accordion__content--nav {
  transition: height 250ms ease-in;
}
.accordion__wrapper.js-collapsed .accordion__content--nav, .js-collapsed.accordion__wrapper--nav .accordion__content--nav {
  box-shadow: 0 3px 6px #00000029;
  padding: 0 1rem;
}
.accordion__wrapper.js-expanded .accordion__content, .accordion__wrapper.js-expanded .accordion__content--nav, .js-expanded.accordion__wrapper--nav .accordion__content, .js-expanded.accordion__wrapper--nav .accordion__content--nav {
  color: #000000;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 1440px) {
  .accordion__wrapper.js-expanded .accordion__content, .accordion__wrapper.js-expanded .accordion__content--nav, .js-expanded.accordion__wrapper--nav .accordion__content, .js-expanded.accordion__wrapper--nav .accordion__content--nav {
    margin-bottom: 4rem;
  }
}
.accordion__wrapper.js-expanded .accordion__title, .js-expanded.accordion__wrapper--nav .accordion__title, .accordion__wrapper.js-expanded .accordion__title--nav, .js-expanded.accordion__wrapper--nav .accordion__title--nav {
  color: #000000;
}
.accordion__wrapper.js-expanded .accordion__icon, .js-expanded.accordion__wrapper--nav .accordion__icon, .accordion__wrapper.js-expanded .accordion__icon--nav, .js-expanded.accordion__wrapper--nav .accordion__icon--nav {
  padding: 10px;
}
.accordion__wrapper.js-expanded .accordion__icon--plus, .js-expanded.accordion__wrapper--nav .accordion__icon--plus {
  display: none;
}
.accordion__wrapper.js-expanded .accordion__icon--minus, .js-expanded.accordion__wrapper--nav .accordion__icon--minus {
  display: inline-block;
  margin-right: -14px;
}
.accordion__wrapper.js-expanded .accordion__content, .accordion__wrapper.js-expanded .accordion__content--nav, .js-expanded.accordion__wrapper--nav .accordion__content, .js-expanded.accordion__wrapper--nav .accordion__content--nav {
  transition: height 250ms ease-out;
}
.accordion__wrapper.js-expanded .accordion__content--nav, .js-expanded.accordion__wrapper--nav .accordion__content--nav {
  box-shadow: 0 3px 6px #00000029;
  padding: 0 1rem;
}

.circle-teaser, .circle-teaser--dark, .circle-teaser--light {
  padding: 3rem 0 0.5rem;
}
@media only screen and (min-width: 768px) {
  .circle-teaser, .circle-teaser--dark, .circle-teaser--light {
    padding: 4rem 0 1.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .circle-teaser, .circle-teaser--dark, .circle-teaser--light {
    padding: 6rem 0 3rem;
  }
}
@media only screen and (min-width: 1200px) {
  .circle-teaser__item-row {
    margin-bottom: 4rem;
  }
}
.circle-teaser__item {
  cursor: pointer;
  margin-bottom: 2.5rem;
}
@media only screen and (min-width: 1200px) {
  .circle-teaser__item {
    margin-bottom: 0;
  }
}
.circle-teaser__item:hover .circle-teaser__link {
  border-bottom: 2px #4CC28F solid;
}
.circle-teaser__img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0 3px 6px #00000029;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.circle-teaser__link {
  color: #4CC28F;
  display: inline-block;
  text-decoration: none;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .circle-teaser__link {
    margin-top: 1rem;
  }
}
.circle-teaser__link:hover {
  border-bottom: 2px #4CC28F solid;
}
.circle-teaser--light {
  background: #ffffff;
  color: #133C7D;
}
.circle-teaser--light .circle-teaser__headline {
  color: #133C7D;
  display: block;
  text-transform: uppercase;
}
.circle-teaser--dark {
  background: #133C7D;
  color: #ffffff;
}
@media only screen and (min-width: 1200px) {
  .circle-teaser--dark {
    padding-bottom: 2rem;
  }
}
.circle-teaser--dark .circle-teaser__headline {
  color: #ffffff;
  display: block;
  text-transform: uppercase;
}

@media only screen and (min-width: 1440px) {
  .dynamic-blocks-container {
    padding: 0 30px;
  }
}
.dynamic-blocks-container .bg-img {
  background-size: 50%;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks-container .bg-img {
    background-size: 60%;
  }
}

.dynamic-blocks, .dynamic-blocks__teaser-block, .dynamic-blocks__video-block, .dynamic-blocks__img-contain, .dynamic-blocks__img-block, .dynamic-blocks__wysiwyg-block {
  height: 270px;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 768px) {
  .dynamic-blocks, .dynamic-blocks__teaser-block, .dynamic-blocks__video-block, .dynamic-blocks__img-contain, .dynamic-blocks__img-block, .dynamic-blocks__wysiwyg-block {
    height: 350px;
    margin-bottom: 5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks, .dynamic-blocks__teaser-block, .dynamic-blocks__video-block, .dynamic-blocks__img-contain, .dynamic-blocks__img-block, .dynamic-blocks__wysiwyg-block {
    height: 430px;
  }
}
@media only screen and (min-width: 1440px) {
  .dynamic-blocks, .dynamic-blocks__teaser-block, .dynamic-blocks__video-block, .dynamic-blocks__img-contain, .dynamic-blocks__img-block, .dynamic-blocks__wysiwyg-block {
    height: 480px;
  }
}
.dynamic-blocks__row:last-of-type {
  margin-bottom: -3rem;
}
@media only screen and (min-width: 768px) {
  .dynamic-blocks__row:last-of-type {
    margin-bottom: -5rem;
  }
}
.dynamic-blocks__img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
}
.dynamic-blocks__wysiwyg-block {
  color: #133C7D;
  padding: 0;
  height: auto;
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks__wysiwyg-block {
    padding: 2rem;
  }
}
.dynamic-blocks__wysiwyg-block .wysiwyg h3 {
  padding-bottom: 0.5rem;
}
@media only screen and (min-width: 1024px) {
  .dynamic-blocks__wysiwyg-block .wysiwyg h3 {
    padding-bottom: 1rem;
  }
}
.dynamic-blocks__wysiwyg-block .block-button {
  margin-top: 1rem;
}
@media only screen and (min-width: 768px) {
  .dynamic-blocks__wysiwyg-block .block-button {
    margin-top: 2rem;
  }
}
.dynamic-blocks__img-contain {
  background-size: contain;
}
.dynamic-blocks__video-block iframe {
  height: 270px;
}
@media only screen and (min-width: 768px) {
  .dynamic-blocks__video-block iframe {
    height: 350px;
  }
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks__video-block iframe {
    height: 430px;
  }
}
@media only screen and (min-width: 1440px) {
  .dynamic-blocks__video-block iframe {
    height: 480px;
  }
}
.dynamic-blocks__teaser-block {
  height: 215px;
}
@media only screen and (min-width: 480px) {
  .dynamic-blocks__teaser-block {
    height: inherit;
  }
}
.dynamic-blocks__teaser-block .outer {
  padding: 3px;
}
@media only screen and (min-width: 1024px) {
  .dynamic-blocks__teaser-block .outer {
    padding: 7px;
  }
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks__teaser-block .outer {
    padding: 10px;
  }
}
.dynamic-blocks__teaser-block .inner {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  cursor: pointer;
  height: 100px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
  transition: all ease-in 0.15s;
}
@media only screen and (min-width: 768px) {
  .dynamic-blocks__teaser-block .inner {
    height: 150px;
  }
}
@media only screen and (min-width: 1024px) {
  .dynamic-blocks__teaser-block .inner {
    height: 140px;
  }
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks__teaser-block .inner {
    height: 190px;
  }
}
.dynamic-blocks__teaser-block .inner:hover {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
  transition: all ease-in 0.15s;
}
.dynamic-blocks__teaser-block .content {
  padding: 1rem;
}
@media only screen and (min-width: 1024px) {
  .dynamic-blocks__teaser-block .content {
    padding: 1.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks__teaser-block .content {
    padding: 1.7rem 1.7rem 3rem;
    -webkit-hyphens: none;
            hyphens: none;
  }
}
.dynamic-blocks__teaser-block .headline {
  line-height: 1.2;
  color: #ffffff;
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks__teaser-block .headline {
    font-size: 1.6rem;
  }
}
.dynamic-blocks__teaser-block .link {
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: absolute;
  bottom: 14px;
  right: 14px;
}
@media only screen and (min-width: 1024px) {
  .dynamic-blocks__teaser-block .link {
    bottom: 18px;
    right: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .dynamic-blocks__teaser-block .link {
    bottom: 38px;
    right: 34px;
  }
}
.dynamic-blocks__img-block {
  background-size: cover;
  background-repeat: no-repeat;
}
.dynamic-blocks__img-contain {
  background-size: contain;
  background-repeat: no-repeat;
}

.html5-video-player a {
  display: none !important;
}

.optional-headline, .optional-headline--light, .optional-headline--dark {
  padding-bottom: 1rem;
}
@media only screen and (min-width: 768px) {
  .optional-headline, .optional-headline--light, .optional-headline--dark {
    padding-bottom: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .optional-headline, .optional-headline--light, .optional-headline--dark {
    padding-bottom: 3.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .optional-headline, .optional-headline--light, .optional-headline--dark {
    padding-bottom: 5rem;
  }
}
.optional-headline__wysiwyg--dark {
  padding-top: 2rem;
}
@media only screen and (min-width: 768px) {
  .optional-headline__wysiwyg--dark {
    padding-top: 3rem;
  }
}
@media only screen and (min-width: 1200px) {
  .optional-headline__wysiwyg--dark {
    padding-top: 6rem;
  }
}
.optional-headline--dark {
  background: #133C7D;
  color: #ffffff;
}
.optional-headline--light {
  background: #ffffff;
  color: #133C7D;
}

.banner-fullwidth--light {
  background: #ffffff !important;
}
.banner-fullwidth--light .banner-fullwidth__content {
  color: #133C7D;
}
.banner-fullwidth__content {
  color: #ffffff;
  padding: 3rem 0;
}
@media only screen and (min-width: 768px) {
  .banner-fullwidth__content {
    padding: 5rem 0;
  }
}
@media only screen and (min-width: 1024px) {
  .banner-fullwidth__content {
    padding: 6rem 0;
  }
}
@media only screen and (min-width: 1200px) {
  .banner-fullwidth__content {
    padding: 4rem 0 7rem 0;
  }
}
.banner-fullwidth__cta {
  margin-top: 1.5rem;
}
@media only screen and (min-width: 1200px) {
  .banner-fullwidth__cta {
    margin-top: 4rem;
  }
}

.search {
  padding-bottom: 4rem;
}
.search button:focus,
.search input:focus,
.search select:focus,
.search textarea:focus {
  outline: none;
}
.search__info {
  color: #133C7D;
}
.search__form {
  margin-bottom: 2rem;
}
.search__input {
  color: #133C7D;
  text-align: left;
  display: block;
  width: 100%;
  padding: 0.8rem 0.3rem;
  border: none;
  box-shadow: 0 3px 6px #00000029;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .search__input {
    padding: 1.5rem 1rem;
  }
}
.search__input::placeholder {
  color: gray;
  padding: 0.5rem 0;
}
.search__results {
  padding-top: 1.5rem;
}
.search__result {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px #133C7D solid;
}
.search__result-title {
  color: #133C7D;
  padding-bottom: 0.2rem;
}
.search__lead {
  margin-bottom: 0.25em;
}
.search__title-link:hover {
  text-decoration: none;
}
.search__url-link {
  color: #133C7D;
}
.search__url-link:hover {
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .teaser__item-container {
    padding: 0.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser__item-container {
    padding: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .teaser__item-container {
    padding: 0.5rem;
  }
}
.teaser__item, .teaser__item--no-link, .teaser__item--with-text, .teaser__item--headline-only {
  box-shadow: 3px 3px 6px #00000029;
  transition: all ease-in 0.15s;
  text-decoration: none;
  padding: 0.8rem 1rem 0.5rem 1rem;
  display: inline-block;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 480px) {
  .teaser__item, .teaser__item--no-link, .teaser__item--with-text, .teaser__item--headline-only {
    padding: 1rem 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .teaser__item, .teaser__item--no-link, .teaser__item--with-text, .teaser__item--headline-only {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .teaser__item, .teaser__item--no-link, .teaser__item--with-text, .teaser__item--headline-only {
    padding: 1.5rem 2rem;
  }
}
.teaser__item:hover, .teaser__item--no-link:hover, .teaser__item--with-text:hover, .teaser__item--headline-only:hover {
  box-shadow: none;
  transition: all ease-in 0.15s;
}
.teaser__item--headline-only {
  min-height: 130px;
}
@media only screen and (min-width: 1200px) {
  .teaser__item--headline-only {
    min-height: 200px;
  }
}
.teaser__item--with-text {
  min-height: 260px;
}
@media only screen and (min-width: 1200px) {
  .teaser__item--with-text {
    min-height: 420px;
  }
}
.teaser__item--no-link:hover {
  box-shadow: 3px 3px 6px #00000029;
}
.teaser__headline {
  color: #133C7D;
  margin-bottom: 0.5rem;
  line-height: 1.15;
}
@media only screen and (min-width: 768px) {
  .teaser__headline {
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser__headline {
    margin-bottom: 2rem;
  }
}
.teaser__wysiwyg {
  color: #133C7D;
  margin-top: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .teaser__wysiwyg {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
}
.teaser__cta-text {
  color: #4CC28F;
  text-decoration: none;
  margin-top: 0.5rem;
  position: absolute;
  bottom: 13px;
  right: 13px;
}
@media only screen and (min-width: 1024px) {
  .teaser__cta-text {
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .teaser__cta-text {
    bottom: 20px;
    right: 25px;
  }
}

.testimonial__img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1024px) {
  .testimonial__img {
    margin-bottom: 0;
  }
}
.testimonial__headline {
  color: #4CC28F;
  margin-bottom: 0.8rem;
}
.testimonial__text {
  color: #133C7D;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1024px) {
  .testimonial__text {
    margin-bottom: 2rem;
  }
}
.testimonial__subheadline {
  color: #133C7D;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.testimonial__link {
  color: #4CC28F;
  text-decoration: none;
}
.testimonial__link:hover {
  text-decoration: underline;
}

.banner-img__img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 400px;
}
@media only screen and (min-width: 1200px) {
  .banner-img__img {
    height: 700px;
  }
}
.banner-img__contain {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 100%;
}
@media only screen and (min-width: 768px) {
  .banner-img__contain {
    background-size: contain;
    padding-top: 56%;
  }
}
@media only screen and (min-width: 1200px) {
  .banner-img__contain {
    height: 700px;
    padding-top: 0%;
  }
}

.banner-video iframe {
  height: 250px;
}
@media only screen and (min-width: 768px) {
  .banner-video iframe {
    height: 400px;
  }
}
@media only screen and (min-width: 1200px) {
  .banner-video iframe {
    height: 500px;
  }
}
@media only screen and (min-width: 1440px) {
  .banner-video iframe {
    height: 700px;
  }
}

.testimonial-slider .slider, .testimonial-slider .slider__control--next, .testimonial-slider .slider__control--prev {
  position: relative;
}
.testimonial-slider .slider__control--next, .testimonial-slider .slider__control--prev {
  transform: scale(1);
  transition: ease-in all 0.15s;
  cursor: pointer;
  position: absolute;
  top: 93px;
  width: 13px;
  display: none !important;
  z-index: 999999 !important;
}
@media only screen and (min-width: 480px) {
  .testimonial-slider .slider__control--next, .testimonial-slider .slider__control--prev {
    top: 125px;
  }
}
@media only screen and (min-width: 768px) {
  .testimonial-slider .slider__control--next, .testimonial-slider .slider__control--prev {
    display: block !important;
    top: 180px;
  }
}
@media only screen and (min-width: 1024px) {
  .testimonial-slider .slider__control--next, .testimonial-slider .slider__control--prev {
    top: 255px;
  }
}
@media only screen and (min-width: 1200px) {
  .testimonial-slider .slider__control--next, .testimonial-slider .slider__control--prev {
    top: 200px;
    width: 17px;
  }
}
.testimonial-slider .slider__control--next:hover, .testimonial-slider .slider__control--prev:hover {
  transform: scale(0.95);
  transition: ease-in all 0.15s;
}
.testimonial-slider .slider__control--prev {
  display: inline-block;
  position: absolute;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .testimonial-slider .slider__control--prev {
    left: -10px;
  }
}
@media only screen and (min-width: 1200px) {
  .testimonial-slider .slider__control--prev {
    left: -30px;
  }
}
.testimonial-slider .slider__control--next {
  display: inline-block;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .testimonial-slider .slider__control--next {
    right: -10px;
  }
}
@media only screen and (min-width: 1200px) {
  .testimonial-slider .slider__control--next {
    right: -30px;
  }
}
.testimonial-slider .slide__portrait-container {
  width: 110px;
  display: block;
  margin: 1rem auto;
}
@media only screen and (min-width: 1200px) {
  .testimonial-slider .slide__portrait-container {
    margin: 2rem auto;
  }
}
.testimonial-slider .slide__portrait {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  box-shadow: 0 3px 6px #00000029;
}
.testimonial-slider .slide__content-box {
  margin: 0 2rem;
  padding: 2rem 1rem;
  box-shadow: 3px 3px 6px #00000029;
  height: auto;
  background: #ffffff;
}
@media only screen and (min-width: 768px) {
  .testimonial-slider .slide__content-box {
    min-height: 300px;
  }
}
@media only screen and (min-width: 1024px) {
  .testimonial-slider .slide__content-box {
    min-height: 400px;
  }
}
@media only screen and (min-width: 1200px) {
  .testimonial-slider .slide__content-box {
    height: 430px;
    margin-bottom: 1rem;
    padding: 3rem 2rem;
    margin: 0 1rem;
  }
}
.testimonial-slider .slide__quotes-symbol {
  display: inline-block;
  margin-bottom: 3rem;
  width: 25px;
}
@media only screen and (min-width: 1024px) {
  .testimonial-slider .slide__quotes-symbol {
    width: 35px;
  }
}
@media only screen and (min-width: 1200px) {
  .testimonial-slider .slide__quotes-symbol {
    width: 50px;
  }
}
.testimonial-slider .slide__quote {
  color: #133C7D;
  -webkit-hyphens: none;
          hyphens: none;
}
.testimonial-slider .slide__author {
  color: #4CC28F;
  text-transform: uppercase;
}
.testimonial-slider .slide__text {
  color: #133C7D;
}

.home-slider {
  position: relative;
}
.home-slider .btn-container {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  left: auto;
  z-index: 99;
  gap: 1px;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
  flex-wrap: nowrap;
}
@media only screen and (min-width: 1200px) {
  .home-slider .btn-container {
    right: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .home-slider .btn-container {
    bottom: 77px;
  }
}
.home-slider__slider-btn {
  position: static;
  display: block;
  padding: 0.4rem 0.7rem;
  width: max-content;
}
@media only screen and (min-width: 768px) {
  .home-slider__slider-btn {
    padding: 1rem 5rem;
  }
}
.home-slider .slider, .home-slider .testimonial-slider .slider__control--prev, .testimonial-slider .home-slider .slider__control--prev, .home-slider .testimonial-slider .slider__control--next, .testimonial-slider .home-slider .slider__control--next {
  margin-bottom: 1rem;
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .home-slider .slider, .home-slider .testimonial-slider .slider__control--prev, .testimonial-slider .home-slider .slider__control--prev, .home-slider .testimonial-slider .slider__control--next, .testimonial-slider .home-slider .slider__control--next {
    margin-bottom: 2rem;
  }
}
.home-slider .slide__img {
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .home-slider .slide__img {
    height: 500px;
  }
}
@media only screen and (min-width: 1024px) {
  .home-slider .slide__img {
    height: 550px;
  }
}
@media only screen and (min-width: 1200px) {
  .home-slider .slide__img {
    height: 630px;
  }
}
.home-slider .slide__content {
  position: absolute;
  left: 10px;
  right: auto;
  bottom: 36px;
}
@media only screen and (min-width: 480px) {
  .home-slider .slide__content {
    bottom: 36px;
  }
}
@media only screen and (min-width: 768px) {
  .home-slider .slide__content {
    left: 50px;
    right: auto;
    bottom: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  .home-slider .slide__content {
    right: 40px;
    left: auto;
    bottom: 30px;
  }
}
.home-slider .slide__content-item {
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .home-slider .slide__content-item {
    margin-bottom: 0.8rem;
  }
}
.home-slider .slide__content-item__time, .home-slider .slide__content-item__time--purple, .home-slider .slide__content-item__time--green, .home-slider .slide__content-item__time--blue {
  display: inline-block;
  color: #ffffff;
  padding: 5px 8px;
}
@media only screen and (min-width: 1200px) {
  .home-slider .slide__content-item__time, .home-slider .slide__content-item__time--purple, .home-slider .slide__content-item__time--green, .home-slider .slide__content-item__time--blue {
    padding: 8px 13px;
  }
}
.home-slider .slide__content-item__time--blue {
  background-color: #133C7D;
}
.home-slider .slide__content-item__time--green {
  background-color: #4CC28F;
}
.home-slider .slide__content-item__time--purple {
  background-color: #4F2D74;
}
.home-slider .slide__content-item__headline {
  background-color: #ffffff;
  color: #000000;
  line-height: 1.2;
  padding: 5px 8px;
  max-width: 250px;
}
@media only screen and (min-width: 1024px) {
  .home-slider .slide__content-item__headline {
    padding: 8px 13px;
    max-width: 400px;
  }
}
.home-slider .slide__content-item__headline b,
.home-slider .slide__content-item__headline strong {
  color: #133C7D;
  line-height: 1.2;
}

.wysiwyg b,
.wysiwyg strong {
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.wysiwyg ol,
.wysiwyg ul {
  padding: 0;
}
.wysiwyg .wysiwyg__text > ol {
  counter-reset: item;
}
.wysiwyg ul li {
  list-style: none;
  background: url("assets/img/svg/plus-green.svg") no-repeat left 0 top 13px;
  display: block;
  list-style: none;
  padding-left: 25px;
}
.wysiwyg ol {
  list-style-type: none;
  padding-left: 0px;
  margin-left: 1.5rem;
}
.wysiwyg ol li:before {
  counter-increment: item;
  content: counters(item);
  color: #4CC28F;
  margin-left: -1.5rem;
  padding-left: -2rem;
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.wysiwyg ol li ol {
  list-style-type: lower-alpha;
}
.wysiwyg ol li ol li {
  margin-left: 30px;
}
@media only screen and (min-width: 1024px) {
  .wysiwyg ol li ol li {
    margin-left: 60px;
  }
}
.wysiwyg ol li ol li:before {
  content: none;
  margin-right: 0;
  color: #4CC28F !important;
}
.wysiwyg ol li ol li::marker {
  right: 4px;
  color: #4CC28F;
  font-family: "sourcesanspro-bold";
  font-weight: 700;
}
.wysiwyg__text {
  color: #133C7D;
}
.wysiwyg__text figure {
  margin-left: 0;
}
.wysiwyg__text figure img {
  max-width: 100%;
}
.wysiwyg__text h2 {
  padding-bottom: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .wysiwyg__text h2 {
    padding-bottom: 1rem;
  }
}
.wysiwyg__text h3 {
  padding-bottom: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .wysiwyg__text h3 {
    padding-bottom: 0.7rem;
  }
}
.wysiwyg__text a {
  color: #133C7D;
}
.wysiwyg__text a:hover {
  text-decoration: none !important;
}

.form *:focus {
  outline: none;
}
.form input:focus,
.form textarea:focus {
  outline: none;
}
.form input:not([type=submit]),
.form select {
  background-image: none;
  background-color: transparent;
}
.form button,
.form input[type=submit] {
  outline: none;
  color: #ffffff;
  border: none;
  float: right;
  cursor: pointer !important;
}
@media only screen and (min-width: 1200px) {
  .form button,
.form input[type=submit] {
    margin-top: 1rem !important;
  }
}
.form input[type=file] {
  font-size: 0.6rem;
}
.form__select .category {
  color: #133C7D !important;
}
.form__title {
  padding-bottom: 0.5rem;
}
@media only screen and (min-width: 1200px) {
  .form__title {
    padding-bottom: 2rem;
  }
}
@media only screen and (min-width: 1440px) {
  .form__title {
    padding-bottom: 1rem;
  }
}
.form__label {
  color: #133C7D;
  text-transform: uppercase;
}
.form__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1200px) {
  .form__group {
    margin-bottom: 1.5rem;
  }
}
.form__group--checkbox, .form__group--radio,
.form__group .form__select {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
}
.form__group:last-of-type + [type=text] {
  display: none;
}
.form__textarea, .form__textinput {
  display: block;
  width: 100%;
  background-color: transparent;
  color: #133C7D;
  border: none;
  box-shadow: 0 3px 6px #00000029;
  padding: 0.3rem;
}
@media only screen and (min-width: 1024px) {
  .form__textarea, .form__textinput {
    padding: 1.5rem 1rem;
  }
}
.form__textarea {
  height: 300%;
}
.form__checkbox {
  margin-right: 0.5rem;
}
.form__optional-text {
  font-size: 66%;
  color: #9fbef0;
}
.form .errors {
  color: #ffffff;
  background-color: #ff004a;
  margin: 0;
  padding: 0.2rem 0.3rem 0.18rem;
}
.form .errors li,
.form .errors ul {
  padding: 0;
  list-style-type: none;
}
.form .errors li {
  font-size: 1rem;
}

textarea {
  padding: 8px;
}

.news-teaser {
  background: #ffffff;
  color: #133C7D;
  padding: 0 0 0.5rem;
}
@media only screen and (min-width: 768px) {
  .news-teaser {
    padding: 0 0 1.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .news-teaser {
    padding: 0 0 3rem;
  }
}
.news-teaser .more-container {
  display: flex;
}
@media (max-width: 768px) {
  .news-teaser .more-container {
    justify-content: center;
  }
}
.news-teaser .more-container .btn--dark, .news-teaser .more-container .form button, .form .news-teaser .more-container button,
.news-teaser .more-container .form input[type=submit],
.form .news-teaser .more-container input[type=submit] {
  margin-left: auto;
  margin-right: 8.666%;
}
@media (max-width: 768px) {
  .news-teaser .more-container .btn--dark, .news-teaser .more-container .form button, .form .news-teaser .more-container button,
.news-teaser .more-container .form input[type=submit],
.form .news-teaser .more-container input[type=submit] {
    margin-right: auto;
  }
}
.news-teaser__headline {
  margin-top: 0.5rem;
  color: #133C7D;
  display: inline-block;
}
@media only screen and (min-width: 480px) {
  .news-teaser__headline {
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .news-teaser__headline {
    margin-top: 1.2rem;
  }
}
@media only screen and (min-width: 1200px) {
  .news-teaser__item-row {
    margin-bottom: 3rem;
  }
}
@media only screen and (min-width: 1440px) {
  .news-teaser__item-row {
    margin-bottom: 4rem;
  }
}
.news-teaser__item {
  cursor: pointer;
  box-shadow: 0 3px 6px #00000029;
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 768px) {
  .news-teaser__item {
    margin-bottom: 2.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .news-teaser__item {
    margin-bottom: 0;
  }
}
.news-teaser__item:hover .news-teaser__link {
  border-bottom: 2px #4CC28F solid;
}
.news-teaser__img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
}
@media only screen and (min-width: 480px) {
  .news-teaser__img {
    height: 180px;
  }
}
@media only screen and (min-width: 768px) {
  .news-teaser__img {
    height: 120px;
  }
}
@media only screen and (min-width: 1024px) {
  .news-teaser__img {
    height: 160px;
  }
}
@media only screen and (min-width: 1200px) {
  .news-teaser__img {
    height: 180px;
  }
}
@media only screen and (min-width: 1440px) {
  .news-teaser__img {
    height: 220px;
  }
}
.news-teaser__link {
  color: #4CC28F;
  display: inline-block;
  text-decoration: none;
  margin-top: 0.5rem;
  position: absolute;
  right: -15px;
  bottom: 10px;
  border-bottom: 2px #ffffff solid;
}
@media only screen and (min-width: 480px) {
  .news-teaser__link {
    right: -25px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .news-teaser__link {
    right: 0;
    bottom: 10px;
  }
}
@media only screen and (min-width: 1024px) {
  .news-teaser__link {
    right: -15px;
    bottom: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  .news-teaser__link {
    right: -8px;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 1440px) {
  .news-teaser__link {
    bottom: 15px;
  }
}
.news-teaser__link:hover {
  border-bottom: 2px #4CC28F solid;
}

.video-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 100px;
}
.video-slider h2 {
  text-align: center;
  margin-bottom: 4vw;
  color: #133c7d;
}

.slider-container {
  width: 80vw;
  max-width: 1600px;
  position: relative;
}
.slider-container__controls {
  position: absolute;
  display: block;
  width: 100%;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
}
.slider-container__controls .right {
  position: absolute;
  right: 0;
  transform: translateX(40px);
  z-index: 9999;
}
.slider-container__controls .left {
  position: absolute;
  left: 0;
  transform: translateX(-40px);
  z-index: 9999;
}
@media (max-width: 768px) {
  .slider-container__controls {
    display: none;
  }
}

.video-button {
  background-color: #133c7d;
  width: 40px;
  height: 40px;
  color: white;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
  width: 35vw;
  max-width: 800px;
  height: 0;
  padding-bottom: 56.25%;
}
.video-container .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  border: none;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
}
.video-container .play-btn img {
  transform: translateX(20%);
}
.video-container:hover .play-btn, .video-container:hover .play-btn:hover {
  display: block;
}

.video-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 90%;
  transform: translate(5%, 5%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.29);
}

.logo-wall {
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .logo-wall__item-container {
    padding: 0.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .logo-wall__item-container {
    padding: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .logo-wall__item-container {
    padding: 0.5rem;
  }
}
.logo-wall .icon__plus, .logo-wall .icon__minus--white, .logo-wall .icon__plus--white {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}
.logo-wall .logo-wall-trigger {
  position: relative;
}
.logo-wall .logo-hover-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 99999;
  transition: all ease-in 0.3s;
}
.logo-wall .logo-hover-container.active {
  opacity: 1;
  z-index: 999999;
  background-color: rgba(19, 60, 125, 0.5);
}
.logo-wall .logo-hover-container.active .logo-wall-hover-wrapper {
  pointer-events: all;
  opacity: 1;
}
.logo-wall .logo-wall-hover-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-height: 100%;
  box-shadow: 3px 3px 6px #00000029;
  background: white;
  padding: 2rem;
  padding-top: 4rem;
  display: flex;
  align-items: start;
  justify-content: center;
  transition: all ease-in 0.3s;
  opacity: 0;
  z-index: -1;
  overflow-y: auto;
}
@media only screen and (min-width: 768px) {
  .logo-wall .logo-wall-hover-wrapper {
    max-height: 70%;
    max-width: 70%;
    height: max-content;
  }
}
@media only screen and (min-width: 1440px) {
  .logo-wall .logo-wall-hover-wrapper {
    max-height: 70%;
    max-width: 50%;
  }
}
.logo-wall .logo-wall-hover-wrapper .overlay-close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
}
.logo-wall__item {
  box-shadow: 3px 3px 6px #00000029;
  transition: all ease-in 0.15s;
  text-decoration: none;
  padding: 0.8rem 1rem 0.5rem;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 130px;
}
@media only screen and (min-width: 480px) {
  .logo-wall__item {
    padding: 1rem 2rem;
  }
}
@media only screen and (min-width: 768px) {
  .logo-wall__item {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .logo-wall__item {
    padding: 1.5rem 2rem;
    height: 200px;
  }
}
.logo-wall__item .circle-teaser__link {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
}
.logo-wall__item .logo-hover-state-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(76, 194, 143, 0.9);
  opacity: 0;
  transition: all ease-in 0.3s;
  pointer-events: none;
}
.logo-wall__item .logo-hover-state-overlay .logo-hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 1.25rem !important;
  font-weight: 700;
  font-family: "sourcesanspro-black";
  text-transform: uppercase;
  width: 100%;
  text-decoration: underline;
  font-size: 2.5rem;
}
@media only screen and (min-width: 768px) {
  .logo-wall__item .logo-hover-state-overlay .logo-hover-text {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1200px) {
  .logo-wall__item:hover {
    box-shadow: 3px 3px 6px #00000029;
  }
  .logo-wall__item:hover .logo-hover-state-overlay {
    opacity: 1;
  }
  .logo-wall__item:hover .icon__plus, .logo-wall__item:hover .icon__minus--white, .logo-wall__item:hover .icon__plus--white {
    opacity: 0;
  }
}
.logo-wall__img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.no-scroll {
  overflow: hidden;
}